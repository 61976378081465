/* eslint-disable jsx-a11y/alt-text */
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EmailIcon from "@mui/icons-material/Email";
import EventNoteIcon from "@mui/icons-material/EventNote";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import {
    Badge,
    Dialog,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { alpha, styled } from "@mui/material/styles";
import { Drawer, Space } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Cookies from "universal-cookie";
import { hiding, selectHeader, showing } from "../features/HeaderSlice";
import { auth } from "../firebase";
import logo from "./HRI-logo.jpeg";
import "./Header.scss";

import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CellTowerIcon from "@mui/icons-material/CellTower";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import Logout from "../Pages/Logout/Logout"
import "./Sidebar.scss";

import {
    CameraAlt,
    CoPresent,
    PresentToAll,
    School,
    WorkspacePremium
} from "@mui/icons-material";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import { setHeaderData } from "../features/HeaderDataSlice";
import { handleControllerResultException, handleException } from "../utils/dataFetchHelper";
import WarningModal from "./WarningModal";

const cookies = new Cookies();

const StyledMenu = styled(props => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const Header = ({ username }) => {
    const navigate = useNavigate();
    const [user] = useAuthState(auth);
    const [openModal , setOpenModal] = useState(false)
    const [visible1, setVisible1] = useState(false);
    const [placement, setPlacement] = useState("left");
    const [active, setActive] = useState("btn1");
    
    const GetDetails = () => {

      axios
        .get(`/hri_admin/header-list`, {
          headers: {
            Authorization: `Token ${cookies.get("token")}`,
  
          }
        })
        .then((res) => {
          dispatch(setHeaderData(res.data))
        })
        .catch((err) => {
          const errRes = handleException(err);
          handleControllerResultException(errRes, navigate);
        });
  
    };

    // const handleOpen = () => {
    //     axios
    //         .get("chat/connect", {
    //             headers: {
    //                 Authorization: "Token " + cookies.get("token"),
    //             },
    //         })
    //         .then(resp => {
    //             console.log("resp", resp.data.redirect_url);
    //             window.open(
    //                 resp.data.redirect_url,
    //                 "_blank",
    //                 "noopener,noreferrer"
    //             );
    //         })
    //         .catch(error => { });
    // };
    const Logout = () => {
        axios
            .post(
                `auth/user/logout`,
                {
                    user_id: localStorage.getItem("user"),
                },
                {
                    headers: {
                        Authorization: "Token " + cookies.get("token"),
                    },
                }
            )
            .then(res => {
                console.log("Logout Successfully");
                cookies.remove("token");
                navigate("/login");
                toast.success("Successfully logged out", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#1ab394", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#BAE8DE" },
                });
            })
            .catch(err => {
                console.log("Error: ", err);
                toast.error("Error logging out", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    style: { backgroundColor: "#FF6347", color: "white" },
                    icon: false,
                    progressStyle: { backgroundColor: "#FFB1A3" },
                });
            });
    };

    // const profileInfo = () => {
    //     axios
    //         .get(`auth/user/profile`, {
    //             headers: {
    //                 Authorization: "Token " + cookies.get("token"),
    //             },
    //         })
    //         .then(res => {
    //             setAdminName(res.data.name);
    //             setProfilePicture(res.data.user.profile.image);
    //         })
    //         .catch(err => {
    //             console.log("Error loading the header profile data", err);
    //         });
    // };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    const dispatch = useDispatch();
  const currentState = useSelector(selectHeader);
  /**
   * @type {import("../features/HeaderDataSlice").HeaderData}
   */
  const headerData = useSelector(state => state.headerData);

    const visible = () => {
        dispatch(
            showing({
                show: true,
            })
        );

        if (currentState?.show === true) {
            dispatch(hiding());
        }
    };

    useEffect(() => {
      if (headerData.isEmpty) {
        GetDetails();
      }
    }, [headerData.isEmpty]);

    const showDrawer = () => {
        setVisible1(true);
    };

    const onClose = () => {
        setVisible1(false);
    };

    const onChange = e => {
        setPlacement(e.target.value);
    };

    const unreadMessage = localStorage.getItem("unreadMessage")

    return (
        <>
            <div className="header_main sticky fixed-top top-0 shadow-md z-40">
                <div className="space-x-5 header_parent_div ">
                    <div className="">
                        {currentState?.show === true ? (
                            <IconButton>
                                {" "}
                                <CloseIcon
                                    onClick={showDrawer}
                                    className="cursor-pointer"
                                />
                            </IconButton>
                        ) : (
                            <IconButton>
                                {" "}
                                <MenuIcon
                                    onClick={showDrawer}
                                    className="cursor-pointer"
                                />
                            </IconButton>
                        )}
                    </div>

                    <div className="logo-container space-x-2 text-base md:text-xl lg:text-xl flex items-center">
                      <Avatar
                        src={headerData.logo || logo}
                        sx={{ width: 38, height: 38 }}
                        className="border-2 border-slate-300 p-[2px] rounded-full"
                      />
                      <div className="font-semibold text-[#0865B6]">{headerData.name || "HR Intelligence"}</div>
                    </div>
                    {/* <IconButton
            onClick={() => {
              handleOpenDialog();
              handleClose();
            }}>
            <Badge badgeContent={3} color="primary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}

          <div className="space-x-0 sm:space-x-1 flex md:space-x-3">
            <IconButton onClick={() => navigate(`/messages/Received`)}>
              <Tooltip title="Message">
                <Badge badgeContent={headerData.unread_msg_count} color="primary">
                  <EmailIcon />
                </Badge>
              </Tooltip>
            </IconButton>
            <IconButton
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              onClick={handleClick}
            >
              <div className="border-1 p-[1px] border-gray-500 rounded-full">
                <Avatar className="h-10 w-10" src={headerData.user_image} alt="user"></Avatar>
                <CameraAlt className="absolute z-1 right-1 bottom-1 bg-white scale-[.6] rounded-lg border-1 border-gray-500" />
              </div>
            </IconButton>
          </div>
          <div>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => navigate("/profile")}
              disableRipple
            >
              <PersonIcon />
              MyProfile
            </MenuItem>
            <MenuItem
              onClick={() => navigate("/settings")}
              disableRipple
            >
              <SettingsIcon />
              Settings
            </MenuItem>
            <MenuItem onClick={() => navigate("/help")} disableRipple>
              <HelpIcon />
              Help & FAQ
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={() => setOpenModal(true)} disableRipple>
              <LogoutIcon />
              Logout
            </MenuItem>
            <WarningModal
              open={openModal}
              close={() => setOpenModal(false)}
              title="Logout ?"
              content="Are you sure you want to Logout ?"
              onclickfunction={Logout}
              cancelText="No"
              agreeText="Yes"
            />
          </StyledMenu>
        </div>
                </div>
            </div>
            <Drawer
                title={
                    <div className="text-[#0865B6]">{headerData.name || "HR Intelligence"}</div>
                }
                placement={placement}
                closable={false}
                onClose={onClose}
                visible={visible1}
                key={placement}
                width={280}
                extra={
                    <Space>
                        {/* <Button onClick={onClose}>Cancel</Button> */}
                        <IconButton onClick={onClose}>
                            <ArrowBackIosNewIcon className="arrow_icon" />
                        </IconButton>
                    </Space>
                }
            >
                <div className="space-y-2">
                    <div
                        onClick={() => navigate("/dashboard")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/dashboard"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <DashboardIcon className="mr-5" />
                            Dashboard
                        </p>
                    </div>

                    <div
                        onClick={() => navigate("/applications")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/applications"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/applications-details"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <InsertDriveFileIcon className="mr-5" />
                            Applications
                        </p>
                    </div>

                    <div
                        onClick={() => navigate("/posted-jobs/active-jobs")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/posted-jobs/active-jobs"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/posted-jobs/job-details"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/posted-jobs/job-details/edit"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/posted-jobs/add-new-position"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <BusinessCenterIcon className="mr-5" />
                            Jobs
                        </p>
                    </div>

                    <div
                        onClick={() => navigate("/broadcast-list")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/broadcast-list"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname === "/broadcast/active"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/broadcast/in-active"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/broadcast/request"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/broadcast/details"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/broadcast/active-details"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/broadcast/inactive-details"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/broadcast/request-details"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/broadcast/message"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <CellTowerIcon className="mr-5" />
                            Broadcast Message
                        </p>
                    </div>

                    <div
                        onClick={() => navigate("/calendar")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/calendar"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <EventNoteIcon className="mr-5" />
                            Calendar
                        </p>
                    </div>

                    <div
                        onClick={() =>
                            navigate("/user-management/registered-user")
                        }
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname ===
                                "/user-management/registered-user"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <SwitchAccountIcon className="mr-5" />
                            User Management
                        </p>
                    </div>
                    <div
                        onClick={() => navigate("/webinar")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/webinar"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <PresentToAll className="mr-5" />
                            Webinar
                        </p>
                    </div>
                    <div
                        onClick={() => navigate("/survey")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/survey"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <CoPresent className="mr-5" />
                            Survey
                        </p>
                    </div>
                    <div
                        onClick={() => navigate("/institute")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/institute"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <School className="mr-2" />
                            Institute
                        </p>
                    </div>

                    <hr className="" />

                    <div
                        onClick={() => navigate("/team-manage/all")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/team-manage/all"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/team-manage/active"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/team-manage/in-active"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/team-manage/add-new-member"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <PeopleAltIcon className="mr-5" />
                            Team Manage
                        </p>
                    </div>

                    <div
                        onClick={() => navigate("/profile-shared")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/profile-shared"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/team-manage/active"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/team-manage/in-active"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/team-manage/add-new-member"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <FolderSharedIcon className="mr-5" />
                            Profile Shared by Me
                        </p>
                    </div>

                    <div
                        onClick={() => navigate("/companies")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/companies"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/companies/companies-details"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/companies/companies-details/edit"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }
                            ${window.location.pathname ===
                                "/companies/companies-details/add"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <CorporateFareIcon className="mr-5" />
                            Companies
                        </p>
                    </div>

                    {/* <div
                        onClick={() => navigate("/Financials/your-bills")}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname
                                .split("/")[1]
                                .toLowerCase() == "financials" &&
                            "bg-neutral-100 text-sky-700"
                            }`}
                    >
                        <p className="m-1">
                            <CurrencyRupeeIcon className="mr-5" />
                            Financials
                        </p>
                    </div> */}
                    {/* <TreeView
            aria-label="controlled"
            // className={`rounded hover:bg-neutral-100 bg-white text-neutral-400 hover:text-sky-700`}
            defaultCollapseIcon={<ExpandMoreIcon className="scale-125" />}
            defaultExpandIcon={<ChevronRightIcon className="scale-125" />}
      // expanded={expanded}
          // selected={selected}
          // onNodeToggle={handleToggle}
          // onNodeSelect={handleSelect}
          > 
          <TreeItem  nodeId="1"
            className={`rounded hover:bg-neutral-100 bg-white text-neutral-400 hover:text-sky-700`}
            
              label={
              <p className="m-2 pl-4 font-bold rounded">
                Services
              </p>}
              >
              <TreeItem nodeId="2"
                className={`rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
               ${window.location.pathname === "/ProfileVerification" ? "bg-neutral-100 text-sky-700"
                    : ""
                  }`}
                onClick={() => { navigate("/ProfileVerification"); setActive("btn10") }}
                label={
                  <p className="flex whitespace-nowrap">
                    <WorkspacePremium className="" />
                    Profile Verification
                  </p>
                } />
              <TreeItem nodeId="3" label="Chrome" />
              <TreeItem nodeId="4" label="Webstorm" />
            </TreeItem>
          </TreeView>  */}

                    <div
                        onClick={() => {
                            navigate("/profileVerification/fresher/All");
                            setActive("btn10");
                        }}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname ===
                                "/profileVerification/fresher/All"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <WorkspacePremium className="mr-5" />
                            Profile Verification
                        </p>
                    </div>
                    <div
                        onClick={() => {
                            navigate("/interview/All");
                            setActive("btn10");
                        }}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold 
                            ${window.location.pathname === "/interview/All"
                                ? "bg-neutral-100 text-sky-700"
                                : ""
                            }`}
                    >
                        <p className="m-1">
                            <PersonIcon className="mr-5" />
                            Interview
                        </p>
                    </div>

                    {/* <div
                        onClick={() => {
                            const token = cookies.get("token");
                            cookies.remove("token");
                            signOut(auth)
                                .then(() => {
                                    navigate("/");
                                })
                                .catch(error => {
                                    console.error(error);
                                });
                        }}
                        className={`hover:bg-neutral-100 rounded flex text-neutral-400 items-center cursor-pointer hover:text-sky-700 p-2 font-bold`}
                    >
                        <p className="m-1">
                            <LogoutIcon className="mr-5" />
                            Logout
                        </p>
                    </div> */}
                </div>
            </Drawer>
            {/* <div
        className="z-10 bg-blue-200 w-16 h-16 p-[10px] rounded-full flex items-center justify-center fixed bottom-10 right-10 hover:cursor-pointer hover:opacity-70 active:opacity-70 duration-300"
        onClick={handleOpen}
      >
        <span>
          <img src={messageIcon} />
        </span>
      </div> */}
        </>
    );
};

export default Header;
